import { useState } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Window } from '@sevenrooms/core/ui-kit/layout'
import { FollowShiftDefaultsModal } from '../../shared/FollowShiftDefaultsModal'
import { DurationsLocales } from '../Durations.locales'
import { DurationsDefault } from './DurationsDefault'

export interface FollowShiftProps {
  field: Field<boolean>
}

export function FollowShift({ field }: FollowShiftProps) {
  return (
    <Checkbox data-test="follow-shift-settings-for-durations" field={field}>
      <FollowShiftMessage />
    </Checkbox>
  )
}

export function FollowShiftMessage() {
  const { formatMessage } = useLocales()
  const [isDefaultsOpen, setIsDefaultsOpen] = useState(false)
  return (
    <>
      {formatMessage(DurationsLocales.followShiftSettingsLabel, {
        a: (chunks: string[]) => (
          <Button variant="tertiary" noPadding onClick={() => setIsDefaultsOpen(true)} data-test="follow-shift-button-durations">
            {chunks}
          </Button>
        ),
      })}
      <Window active={isDefaultsOpen}>
        <FollowShiftDefaultsModal title={formatMessage(DurationsLocales.defaultSettingsModalTitle)} onDone={() => setIsDefaultsOpen(false)}>
          <DurationsDefault showGuestDurationPickerEnabledWarning={false} />
        </FollowShiftDefaultsModal>
      </Window>
    </>
  )
}
