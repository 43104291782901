import { defineMessages } from '@sevenrooms/core/locales'

export const DurationsLocales = defineMessages({
  title: {
    id: 'ar.durations.title',
    defaultMessage: 'Durations',
  },
  subCaption: {
    id: 'ar.durations.subCaption',
    defaultMessage: 'Override Shift Durations',
  },
  description: {
    id: 'ar.durations.description',
    defaultMessage: 'Customize the Durations for your tasting menu so these guests have more time than guests ordering à la carte.',
  },
  headerPartyDuration: {
    id: 'ar.durations.headerPartyDuration',
    defaultMessage: 'Party Duration',
  },
  headerPartySize: {
    id: 'ar.durations.headerPartySize',
    defaultMessage: 'Party Size',
  },
  followShiftSettingsLabel: {
    id: 'ar.durations.followShiftSettingsLabel',
    defaultMessage: 'Follow <a>Shift Settings</a> for Durations',
  },
  defaultSettingsModalTitle: {
    id: 'ar.durations.defaultSettingsModalTitle',
    defaultMessage: 'Shift Settings - Durations',
  },
  oneGuests: {
    id: 'ar.durations.oneGuests',
    defaultMessage: '1 guest',
  },
  numGuests: {
    id: 'ar.durations.numGuests',
    defaultMessage: '{num} guests',
  },
  partySizeRange: {
    id: 'ar.durations.partySizeRange',
    defaultMessage: '{start} - {end}',
  },
  useDurationsAllPartySizes: {
    id: 'ar.durations.useDurationsAllPartySizes',
    defaultMessage: 'Assign same Duration to all Party Sizes',
  },
  useDurationsByPartySize: {
    id: 'ar.durations.useDurationsByPartySize',
    defaultMessage: 'Assign customized Duration per Party Size',
  },
  errorDurationRequired: {
    id: 'ar.durations.errorDurationRequired',
    defaultMessage: 'Duration required',
  },
  buffersNote: {
    id: 'ar.durations.buffersNote',
    defaultMessage: 'Please note: If buffers are set on the shift they will be applied on top of any durations set here',
  },
  partySizeNote: {
    id: 'ar.durations.partySizeNote',
    defaultMessage: 'Please note: Grayed out Party Sizes are outside the Min Party Size and Max Party Size for this Access Rule.',
  },
  guestDurationPickerEnabledWarning: {
    id: 'ar.durations.guestDurationPickerEnabledWarning',
    defaultMessage:
      'You have enabled and customised a min-max duration of the Guest Duration Picker for this Access Rule, so you can’t customise the Duration. If you wish to do so, first restore the default settings of the Guest Duration Picker.',
  },
} as const)
