import { type Field, useWatch, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { AccessRuleSlideoutSection, Divider } from '../shared'
import { Note } from '../shared/Note'
import { DurationsByPartySizeFields } from './components/DurationsByPartySizeFields'
import { DurationsCollapsed } from './components/DurationsCollapsed'
import { DurationsDefault } from './components/DurationsDefault'
import { FollowShift } from './components/FollowShift'
import { DurationsLocales } from './Durations.locales'
import type { DurationsFieldProps } from './DurationsFieldProps'
import type { GuestDurationPickerForm } from '../GuestDurationPicker/GuestDurationPicker.zod'
import type { PartySizeForm } from '../PartySize/PartySize.zod'

export interface DurationsEditProps extends DurationsFieldProps {
  guestDurationPickerField: Field<GuestDurationPickerForm>
  partySizeField: Field<PartySizeForm>
}

export function Durations({ field, guestDurationPickerField, partySizeField }: DurationsEditProps) {
  const { formatMessage } = useLocales()
  const { guestMustSpecifyDuration } = useWatch(guestDurationPickerField)
  const durationOptions = useDurationOptions()

  const useShiftDurations = useWatch(field.prop('useShiftDurations'))
  const useDurationAllPartySizes = useWatch(field.prop('useDurationAllPartySizes'))
  const useDurationsByPartySize = useWatch(field.prop('useDurationsByPartySize'))

  const useDurationAllPartySizesController = useController(field.prop('useDurationAllPartySizes'))
  const useDurationsByPartySizeController = useController(field.prop('useDurationsByPartySize'))

  return (
    <AccessRuleSlideoutSection
      data-test="access-rules-slideout-section-durations"
      title={formatMessage(DurationsLocales.title)}
      description={formatMessage(DurationsLocales.description)}
      subCaption={formatMessage(DurationsLocales.subCaption)}
      contentWhenCollapsed={<DurationsCollapsed field={field} />}
      defaultSettings={<DurationsDefault showGuestDurationPickerEnabledWarning={guestMustSpecifyDuration} />}
      sectionControllerName="durations"
      field={field}
      disableEdit={guestMustSpecifyDuration}
    >
      <FollowShift field={field.prop('useShiftDurations')} />
      <Note>{formatMessage(DurationsLocales.buffersNote)}</Note>
      <Divider />
      {!useShiftDurations && (
        <VStack spacing="m" mt="m">
          <Checkbox
            field={field.prop('useDurationAllPartySizes')}
            onChange={e => e.currentTarget.checked && useDurationsByPartySizeController.field.onChange(false)}
          >
            {formatMessage(DurationsLocales.useDurationsAllPartySizes)}
          </Checkbox>
          <Box ml="m">
            <FormSelect
              withEmpty
              field={field.prop('durationAllPartySizes')}
              options={durationOptions}
              searchable={false}
              data-test="access-rule-duration-all-party-sizes"
              disabled={!useDurationAllPartySizes}
            />
          </Box>
          <Checkbox
            field={field.prop('useDurationsByPartySize')}
            onChange={e => e.currentTarget.checked && useDurationAllPartySizesController.field.onChange(false)}
          >
            {formatMessage(DurationsLocales.useDurationsByPartySize)}
          </Checkbox>
          {useDurationsByPartySize && <DurationsByPartySizeFields field={field} partySizeField={partySizeField} />}
        </VStack>
      )}
    </AccessRuleSlideoutSection>
  )
}
