import { OfferTypeEnum } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { SectionController, Segment, type SegmentProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { accessRulesMessages } from '../../AccessRules.locales'
import { AccessRuleTestId } from '../../AccessRules.testIds'
import { BookingChannels } from '../BookingChannels'
import { BookingWindow } from '../BookingWindow'
import { Durations } from '../Durations/Durations'
import { GuestDurationPicker } from '../GuestDurationPicker'
import { GuestFacing } from '../GuestFacing'
import { Pacing } from '../Pacing'
import { PartySize } from '../PartySize'
import { PaymentPolicy } from '../PaymentPolicy'
import { ReservationCoverLimit } from '../ReservationCoverLimit'
import { ReservationTags } from '../ReservationTags'
import { Schedule } from '../Schedule'
import { ScheduleLocales } from '../Schedule/Schedule.locales'
import { SeatingAreas } from '../SeatingAreas'
import { useAccessRuleContext } from '../shared'
import { Upgrades } from '../Upgrades'
import type { AccessRuleForm } from '../../AccessRule.zod'
import type { PropsWithChildren } from 'react'

export interface EditModeProps {
  field: Field<AccessRuleForm>
  isOverride: boolean
  disabledFields?: string[]
}

export function EditMode({ field, isOverride, disabledFields }: EditModeProps) {
  const { formatMessage } = useLocales()
  const { experiences } = useAccessRuleContext()
  const { durationsByAccessRuleEnabled } = useAppContext().venueSettings

  const experienceId = useWatch(field.prop('guestFacing.offer'))
  const experience = experienceId && experiences ? experiences.find(experience => experience?.id === experienceId) : undefined
  const isPrivateEventAccessRule = experience?.offerType === OfferTypeEnum.PDR
  return (
    <VStack spacing="l">
      <Label primary={formatMessage(accessRulesMessages.nameLabel)}>
        <FormInput
          data-test={AccessRuleTestId.name}
          placeholder={formatMessage(accessRulesMessages.titlePlaceholder)}
          field={field.prop('name')}
        />
      </Label>

      {isOverride ? (
        <Segment
          data-test={AccessRuleTestId.schedule.section}
          header={formatMessage(ScheduleLocales.timeSlotsLabel)}
          description={formatMessage(ScheduleLocales.timeSlotsDescription)}
        >
          <Schedule field={field.prop('schedule')} showTimeOnly />
        </Segment>
      ) : (
        <Segment
          data-test={AccessRuleTestId.schedule.section}
          header={formatMessage(accessRulesMessages.scheduleHeader)}
          description={formatMessage(accessRulesMessages.scheduleDescription)}
        >
          <Schedule field={field.prop('schedule')} />
        </Segment>
      )}

      <SectionController behavior="one-section-at-one-time">
        <AccessRuleSegment
          data-test={AccessRuleTestId.basicSettings}
          header={formatMessage(accessRulesMessages.basicSettingsHeader)}
          description={formatMessage(accessRulesMessages.basicSettingsDescription)}
        >
          <VStack spacing="m">
            <PartySize field={field.prop('partySize')} isPrivateEventAccessRule={isPrivateEventAccessRule} />
            <SeatingAreas field={field.prop('seatingAreas')} disabled={disabledFields?.includes('seatingAreas')} />
            <GuestFacing
              field={field.prop('guestFacing')}
              bookingChannels={field.prop('bookingChannels')}
              paymentPolicy={field.prop('paymentPolicy')}
              isPrivateEventAccessRule={isPrivateEventAccessRule}
            />
            <PaymentPolicy field={field.prop('paymentPolicy')} allowChannelsWithoutCCHolds={field.prop('allowChannelsWithoutCCHolds')} />
          </VStack>
        </AccessRuleSegment>

        <AccessRuleSegment
          data-test={AccessRuleTestId.additionalSettings}
          header={formatMessage(accessRulesMessages.additionalSettingsHeader)}
          description={formatMessage(accessRulesMessages.additionalSettingsDescription)}
        >
          <VStack spacing="m">
            <BookingChannels
              field={field.prop('bookingChannels')}
              bookingWindowField={field.prop('bookingWindow')}
              disabled={disabledFields?.includes('bookingChannels')}
            />
            {durationsByAccessRuleEnabled && (
              <Durations
                field={field.prop('durations')}
                guestDurationPickerField={field.prop('guestDurationPicker')}
                partySizeField={field.prop('partySize')}
              />
            )}
            <Upgrades field={field.prop('upgrades')} />
            <ReservationTags field={field.prop('reservationTags')} />
            <BookingWindow field={field.prop('bookingWindow')} bookingChannelsField={field.prop('bookingChannels')} />
            <ReservationCoverLimit field={field.prop('reservationCoverLimit')} />
            <Pacing field={field.prop('pacing')} />
            <GuestDurationPicker field={field.prop('guestDurationPicker')} durationsField={field.prop('durations')} />
          </VStack>
        </AccessRuleSegment>
      </SectionController>
    </VStack>
  )
}

function AccessRuleSegment(props: PropsWithChildren<SegmentProps>) {
  return <Segment type="h2" {...props} />
}
