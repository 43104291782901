import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { Note } from '../../shared/Note'
import { DurationsLocales } from '../Durations.locales'
import type { PartySizeForm } from '../../PartySize/PartySize.zod'
import type { PartySizeKey } from '../Constants'
import type { DurationsFieldProps } from '../DurationsFieldProps'

interface DurationsByPartySizeFieldsProps extends DurationsFieldProps {
  partySizeField: Field<PartySizeForm>
}

export function DurationsByPartySizeFields({ field, partySizeField }: DurationsByPartySizeFieldsProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="m" m="m">
      <HStack justifyContent="space-between">
        <DurationForPartySizeField partySize={1} field={field} partySizeField={partySizeField} />
        <DurationForPartySizeField partySize={6} field={field} partySizeField={partySizeField} />
      </HStack>
      <HStack justifyContent="space-between">
        <DurationForPartySizeField partySize={2} field={field} partySizeField={partySizeField} />
        <DurationForPartySizeField partySize={7} field={field} partySizeField={partySizeField} />
      </HStack>
      <HStack justifyContent="space-between">
        <DurationForPartySizeField partySize={3} field={field} partySizeField={partySizeField} />
        <DurationForPartySizeField partySize={8} field={field} partySizeField={partySizeField} />
      </HStack>
      <HStack justifyContent="space-between">
        <DurationForPartySizeField partySize={4} field={field} partySizeField={partySizeField} />
        <DurationForPartySizeField partySize={9} field={field} partySizeField={partySizeField} />
      </HStack>
      <HStack justifyContent="space-between">
        <DurationForPartySizeField partySize={5} field={field} partySizeField={partySizeField} />
        <DurationForPartySizeField partySize={10} field={field} partySizeField={partySizeField} />
      </HStack>
      <Note>{formatMessage(DurationsLocales.partySizeNote)}</Note>
    </VStack>
  )
}

interface DurationForPartySizeFieldProps extends DurationsByPartySizeFieldsProps {
  partySize: number
}

function DurationForPartySizeField({ partySize, field, partySizeField }: DurationForPartySizeFieldProps) {
  const { formatMessage } = useLocales()
  const durationOptions = useDurationOptions()
  const { min, max } = useWatch(partySizeField)

  const durationsByPartySizeField = field.prop('durationsByPartySize')

  return (
    <HStack spacing="m">
      <Text>{formatMessage(DurationsLocales.numGuests, { num: partySize })}</Text>
      <FormSelect
        withEmpty
        field={durationsByPartySizeField.prop(partySize.toString() as PartySizeKey)}
        options={durationOptions}
        searchable={false}
        data-test={`access-rule-duration-party-size-${partySize}`}
        disabled={Boolean(min && partySize < min) || Boolean(max && partySize > max)}
      />
    </HStack>
  )
}
