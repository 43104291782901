import { useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { Box, Pair } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CoreLocales } from '@sevenrooms/mgr-core/core.locales'
import { Note } from '../../shared/Note'
import { PARTY_SIZES, type PartySizeKey } from '../Constants'
import { DurationsLocales } from '../Durations.locales'
import type { DurationsForm } from '../Durations.zod'
import type { DurationsFieldProps } from '../DurationsFieldProps'

export function DurationsCollapsed({ field }: DurationsFieldProps) {
  const { formatMessage } = useLocales()
  const data = useWatch(field)

  return (
    <Text fontSize="m">
      <Text fontWeight="bold">
        <Pair
          left={<Text fontWeight="bold">{formatMessage(DurationsLocales.headerPartySize)}</Text>}
          right={<Text fontWeight="bold">{formatMessage(DurationsLocales.headerPartyDuration)}</Text>}
        />
      </Text>
      {data.useDurationAllPartySizes && data.durationAllPartySizes ? (
        <Pair
          left={<Text>1 - 10+</Text>}
          right={formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(data.durationAllPartySizes))}
        />
      ) : (
        <DurationsForPartySizeRanges {...data} />
      )}
      <Note>{formatMessage(DurationsLocales.partySizeNote)}</Note>
    </Text>
  )
}

function DurationsForPartySizeRanges({ durationsByPartySize }: DurationsForm) {
  const { formatMessage } = useLocales()

  const durationsForPartySizeRanges: { start: PartySizeKey; end?: PartySizeKey; duration: number | null }[] = []
  for (const partySize of PARTY_SIZES) {
    const duration = durationsByPartySize[partySize] ?? null
    const previous = durationsForPartySizeRanges.at(-1)
    if (previous && duration === previous?.duration) {
      previous.end = partySize
    } else {
      durationsForPartySizeRanges.push({ start: partySize, duration })
    }
  }

  return (
    <>
      {durationsForPartySizeRanges.map(({ start, end, duration }) => {
        const key = start === end || !end ? start : formatMessage(DurationsLocales.partySizeRange, { start, end })
        return (
          <Box key={key} mt="m">
            <Pair
              left={<Text color={!duration ? 'deactivated' : undefined}>{key}</Text>}
              right={
                duration ? formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(duration)) : <Text color="deactivated">-</Text>
              }
            />
          </Box>
        )
      })}
    </>
  )
}
