import { useLocales } from '@sevenrooms/core/locales'
import { Report, ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { accessRulesMessages } from '../../../AccessRules.locales'
import { DefaultSettingsReportSection, useAccessRuleContext } from '../../shared'
import { Note } from '../../shared/Note'
import { DurationsLocales } from '../Durations.locales'

export function DurationsDefault({ showGuestDurationPickerEnabledWarning }: { showGuestDurationPickerEnabledWarning: boolean }) {
  const { formatMessage } = useLocales()
  const { shifts } = useAccessRuleContext()

  return (
    <>
      {showGuestDurationPickerEnabledWarning && <Note isWarning>{formatMessage(DurationsLocales.guestDurationPickerEnabledWarning)}</Note>}
      <DefaultSettingsReportSection>
        <Report>
          <ReportPart
            caption={[
              formatMessage(accessRulesMessages.shift),
              formatMessage(DurationsLocales.headerPartySize),
              formatMessage(DurationsLocales.headerPartyDuration),
            ]}
          >
            {shifts.map(shift => (
              <>
                <ReportLine key={shift.id} name={shift.name}>
                  <>
                    {shift.minPartySize} - {shift.maxPartySize}
                  </>
                  <>todo</>
                </ReportLine>
              </>
            ))}
          </ReportPart>
        </Report>
      </DefaultSettingsReportSection>
    </>
  )
}
