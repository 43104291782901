import { useMemo } from 'react'
import type { AccessRule, Shift, SeatingAreaToTables } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useMultiSelectTreeForm } from '@sevenrooms/core/ui-kit/form'
import { notNullish } from '@sevenrooms/core/utils'
import type { GoogleReserveSeatingAreas, TheForkSeatingAreas } from './integrationOptions'

export type SeatingAreasForm = ZodSchema<typeof useSeatingAreasForm>

export function useSeatingAreasForm() {
  const selection = useMultiSelectTreeForm<{ isTable: boolean; isBookable?: boolean }>()
  return useMemo(
    () =>
      z.object({
        selection,
        treatAsBlocked: z.boolean(),
        googleReserveSeatingArea: z.custom<GoogleReserveSeatingAreas>(),
        theForkSeatingArea: z.custom<TheForkSeatingAreas>(),
      }),
    [selection]
  )
}

export function getShiftSeatingAreas(shifts: Shift[], seatingAreaToTables: SeatingAreaToTables[]) {
  return shifts.map(({ id, name, seatingAreaIds }) => {
    const seatingAreas = seatingAreaToTables.filter(seatingArea => seatingAreaIds.includes(seatingArea.id))
    return {
      id,
      name,
      seatingAreas,
      tables: seatingAreas.flatMap(({ tables }) => tables),
    }
  })
}

export function getInitialSeatingAreas(arState: {
  accessRule?: AccessRule
  shifts: Shift[]
  seatingAreaToTables: SeatingAreaToTables[]
}): SeatingAreasForm {
  const { accessRule, seatingAreaToTables } = arState
  const allTables = seatingAreaToTables.flatMap(({ tables }) => tables)
  const shiftSeatingAreas = getShiftSeatingAreas(arState.shifts, seatingAreaToTables).map(({ seatingAreas, tables }) => ({
    seatingAreas: seatingAreas.map(({ id }) => id),
    tables: tables.map(({ id }) => id),
  }))

  const seatingAreas =
    accessRule?.seatingAreaIDS
      .map(id => seatingAreaToTables.find(seatingArea => seatingArea.id === id))
      .filter(notNullish)
      .map(seatingArea => ({
        checked: true,
        id: seatingArea.id,
        label: seatingArea.name,
        value: {
          isTable: false,
          // If seatingAreas is empty, it means that "All" seating areas are selected for the shift
          isBookable: shiftSeatingAreas.every(({ seatingAreas }) =>
            seatingAreas.length === 0 ? true : seatingAreas.includes(seatingArea.id)
          ),
        },
      })) ?? []

  const tables =
    accessRule?.tableIDS
      .map(id => allTables.find(table => table.id === id))
      .filter(notNullish)
      .map(table => ({
        checked: true,
        id: table.id,
        label: table.itemCode,
        value: {
          isTable: true,
          // If tables is empty, it means that "All" seating areas are selected for the shift
          isBookable: shiftSeatingAreas.every(({ tables }) => (tables.length === 0 ? true : tables.includes(table.id))),
        },
      })) ?? []

  return {
    selection: [...seatingAreas, ...tables],
    treatAsBlocked: accessRule?.isHeld ?? false,
    googleReserveSeatingArea: (accessRule?.googleReserveSeatingArea as GoogleReserveSeatingAreas) ?? 'NOT_USING',
    theForkSeatingArea: (accessRule?.theForkSeatingArea as TheForkSeatingAreas) ?? 'INSIDE',
  }
}
