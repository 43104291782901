import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { accessRulesMessages } from '../../AccessRules.locales'
import { SharedTestId } from './shared.testIds'

export interface DefaultsModalProps {
  title: string
  onDone?: () => void
}

export function FollowShiftDefaultsModal({ title, onDone, children }: React.PropsWithChildren<DefaultsModalProps>) {
  const { formatMessage } = useLocales()

  return (
    <Modal data-test={SharedTestId.defaultSettingsModal} ariaLabel="main" width={700}>
      <ModalHeader onClose={onDone}>
        <ModalTitle
          title={title}
          subTitle={formatMessage(accessRulesMessages.defaultSettingsModalSubtext, {
            strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
          })}
        />
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button data-test={SharedTestId.defaultSettingsDone} variant="primary" onClick={onDone}>
            {formatMessage(commonMessages.done)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
