import { useLocales } from '@sevenrooms/core/locales'
import { getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { ReportLine, ReportPart } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CoreLocales } from '@sevenrooms/mgr-core/core.locales'
import { DurationsLocales } from '../Durations.locales'
import type { AccessRuleForm } from '../../../AccessRule.zod'

export function DurationsReport({ accessRule }: { accessRule: AccessRuleForm }) {
  const { formatMessage } = useLocales()

  const min = accessRule?.partySize?.min ?? 0
  const max = accessRule?.partySize?.max ?? Number.MAX_VALUE

  return (
    <ReportPart caption={formatMessage(DurationsLocales.title)} data-test="access-rules-slideout-report-durations">
      {Object.entries(accessRule.durations.durationsByPartySize)
        .map(([partySize, duration]) => ({
          partySize: parseInt(partySize),
          duration,
        }))
        .sort((a, b) => a.partySize - b.partySize)
        .filter(x => x.duration && x.partySize >= min && x.partySize <= max)
        .map(({ partySize, duration }) => (
          <ReportLine key={`party-size-duration-${partySize}`} name={formatMessage(DurationsLocales.numGuests, { num: partySize })}>
            {duration && <Text>{formatMessage(CoreLocales.durationTimeFormat, getHoursAndMinutes(duration))}</Text>}
          </ReportLine>
        ))}
    </ReportPart>
  )
}
